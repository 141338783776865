module.exports = [{
      plugin: require('/mnt/freshcells/tierheim/tierheim-dus-frontend/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/mnt/freshcells/tierheim/tierheim-dus-frontend/src/translations","languages":["de"],"defaultLanguage":"de","redirect":true},
    },{
      plugin: require('/mnt/freshcells/tierheim/tierheim-dus-frontend/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":1,"matomoUrl":"https://stat.tierheim-duesseldorf.de","siteUrl":"https://tierheim-duesseldorf.de","requireConsent":true,"requireCookieConsent":true},
    },{
      plugin: require('/mnt/freshcells/tierheim/tierheim-dus-frontend/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

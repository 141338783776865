// @flow

import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { reducer as formReducers } from 'redux-form'


const configureStore = (initialState: Object) => {
  const reducers = {
    form: formReducers
  }

  const middleware = [ thunk ]

  const enhancers = []
  const isDevelopment = process.env.NODE_ENV === 'development'

  if (isDevelopment && typeof window !== undefined && window.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(window.devToolsExtension())
  }

  const rootReducer = combineReducers({
    ...reducers
  })

  return createStore(rootReducer, initialState, compose(
    applyMiddleware(...middleware), ...enhancers)
  )
}

export { configureStore }

import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from './src/state/createStore'
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './src/apollo/client';

export default ({ element }) => {
  const store = configureStore({});
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        {element}
      </ApolloProvider>
    </Provider>
  )
}
